var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { addJourneyById } from '..';
import { JOURNEY_URL, NODE_ENV } from '../../config';
import { JourneyEventType } from '../../types';
import { getDefaultIframeNameByLang } from '../../utils/getDefaultIframeNameByLang';
import { getQueryParams } from '../../utils/getQueryParams';
import { mutateIframeStyling } from '../../utils/mutateIframeStyling';
function iframeCreate(params) {
    var _a;
    var _b;
    var journeyId = params.journeyId, lang = params.lang, payload = __rest(params, ["journeyId", "lang"]);
    var mode = params.mode, topBar = params.topBar;
    // add queryParams
    var queryParams = getQueryParams();
    payload['queryParams'] = queryParams;
    // get container where iframe will be placed in
    var container = document.getElementById("epilot-journey-" + journeyId);
    var iframe = document.createElement('iframe');
    iframe.style.border = 'none';
    if (NODE_ENV === 'test') {
        iframe.setAttribute('data-testid', journeyId);
    }
    var iframeSrc = new URL(
    // url can be overridden (alternative hosting for journey app)
    (_b = params.journeyUrl) !== null && _b !== void 0 ? _b : JOURNEY_URL);
    iframeSrc.searchParams.set('journeyId', journeyId);
    iframeSrc.searchParams.set('isEmbedded', 'true');
    if (mode) {
        iframeSrc.searchParams.set('mode', mode);
    }
    if (typeof topBar === 'boolean') {
        iframeSrc.searchParams.set('topBar', topBar.toString());
    }
    if (lang) {
        // TODO: infer language from journey settings instead
        // @see https://e-pilot.atlassian.net/browse/ER-4051
        iframeSrc.searchParams.set('lang', lang);
    }
    iframe.setAttribute('name', payload.name || getDefaultIframeNameByLang(lang));
    iframe.setAttribute('title', payload.name || getDefaultIframeNameByLang(lang));
    if (payload.dataInjectionOptions) {
        iframeSrc.searchParams.set('dataInjectionOptions', window.btoa(JSON.stringify(payload.dataInjectionOptions)));
    }
    for (var _i = 0, _c = Object.entries(queryParams); _i < _c.length; _i++) {
        var _d = _c[_i], key = _d[0], value = _d[1];
        if (value) {
            iframeSrc.searchParams.set(key, value);
        }
    }
    // assign the src to the iframe
    iframe.src = iframeSrc.toString();
    // pass params to iframe once loaded. Not using `on` listeners here since it dispatches another event asynchronously
    // and we want to pass the params immediately.
    window.addEventListener('message', function (event) {
        var _a, _b;
        if (((_a = event === null || event === void 0 ? void 0 : event.data) === null || _a === void 0 ? void 0 : _a.type) === JourneyEventType.journeyIframeReady) {
            var _c = event.data, payload_1 = _c.payload, journeyIdToInit = _c.journeyId;
            if (journeyIdToInit === journeyId) {
                (_b = iframe === null || iframe === void 0 ? void 0 : iframe.contentWindow) === null || _b === void 0 ? void 0 : _b.postMessage({
                    type: JourneyEventType.init,
                    status: 'effect',
                    journeyId: journeyId,
                    payload: __assign(__assign({}, payload_1), { initialMessageEventReceived: true })
                }, '*');
                // Set styling depending on mode
                if (params.mode === 'inline') {
                    mutateIframeStyling(iframe, params);
                }
            }
        }
    });
    if (params.mode === 'inline') {
        iframe.style.cssText =
            'display:block; border: none; min-width: 100%; width: 1px';
    }
    else {
        mutateIframeStyling(iframe, params);
    }
    // append iframe to HTML
    if (container) {
        container.appendChild(iframe);
        iframe.id = "epilot-journey-embedded-" + journeyId;
    }
    else {
        iframe.id = "epilot-journey-" + journeyId;
        document.body.appendChild(iframe);
    }
    // add journey to map and return created
    var journey = (_a = {},
        _a[journeyId] = { el: iframe, isInitialized: true },
        _a);
    addJourneyById(journeyId, {
        el: iframe,
        isInitialized: true
    });
    return journey;
}
export default iframeCreate;
